/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, ContactForm, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Investovanie do nehnuteľností"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--60 pt--60" name={"[[UNIsecname1]]"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Investícia, krytá nehnuteľnosťou, s výnosom 15% !"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"[[UNIgenericsecname2]]"}>
          
          <ColumnWrap className="column__flex el--1" style={{"maxWidth":1200}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Zabezpečená investícia, s atraktívnym výnosom&nbsp; až do výšky 15% ročne!<br>Tento unikátny investičný projekt spája, bezpečnosť, atraktívny výnos, krátkodobú návratnosť do 1. roka a flexibilitu.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"jv0mpz5dnme"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Prečo investovať s nami?"}>
              </Title>

              <Subtitle className="subtitle-box" content={"Bezpečnosť"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Vaša investícia je chránená nehnuteľnosťou, ktorej hodnota presahuje samotnú investíciu. Pred každým krokom máte možnosť nehnuteľnosť schváliť, pričom si zabezpečujete záložné právo alebo sa priamo stávate jej vlastníkom."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Atraktívny výnos"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Zisky sú mimoriadne lákavé a prichádzajú rýchlo. Priemerný čas na návrat vložených peňazí spolu so ziskom je 7 mesiacov. Výnos sa pohybuje okolo 15 %. V niektorých prípadoch však nie je nezvyčajné dosiahnuť ročný výnos až 30 %."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Flexibilita a seriózne podmienky"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Investujete len vtedy, keď sami chcete, a presne do toho projektu, o ktorom máte dostatočné informácie a ktorý schválite. Znamená to, že vždy máte jasno, kam presne idú vaše peniaze a za akých podmienok. Finančné prostriedky sa investujú až po vašom súhlase, dovtedy ich bezpečne držíte na svojom účte."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"9cf9cj6894j"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Chcete vedieť viac?"}>
              </Title>

              <Subtitle className="subtitle-box mt--16" content={"Kontaktujte nás"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":740}} content={"Prosím, zadajte svoje kontaktné údaje, aby sme mohli zorganizovať osobné stretnutie alebo videohovor, na ktorom vám poskytneme detailnejšie informácie."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Meno a priezvisko","type":"text","required":true,"placeholder":"Meno a priezvisko","id":"enpeshme4wp7"},{"name":"Email","type":"email","required":true,"placeholder":"Email","id":"1fnkffs2l9qx"},{"name":"Tel. číslo","type":"text","placeholder":"Tel. číslo","id":"tvrshqz6cnx4"},{"name":"Správa","type":"textarea","required":false,"placeholder":"Správa","id":"37ijf8h34fvt"},{"name":"Odoslať","type":"submit","id":"esah2n2r806y"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="css-g943z5 pb--80 pt--80" name={"[[UNIsectionname3]]"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">[[MAKphrase1]]</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape4" content={"[[UNIContactMe]]"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"[[UNIsecname7]]"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style='color: var(--color-dominant)'>[[UNIing]] [[UNIname3]]</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style='color: var(--color-dominant)'>[[UNICurrentYear]] – [[UNICreated]]</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}